import Vue from 'vue'
import App from './App.vue'
import router from './router';

import ElementUI from 'element-ui'
import './assets/style/element-variables.scss'
//import 'element-ui/lib/theme-chalk/index.css';

import '../public/icon/iconfont.css'
import '@/assets/css/global.scss'
import axios from 'axios';

Vue.config.productionTip = false

// 全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false
// 全局修改默认配置，按下ESC不能关闭弹窗
ElementUI.Dialog.props.closeOnPressEscape.default = false
//按需引入
Vue.use(ElementUI)

Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});

new Vue({

  render: h => h(App),
  async beforeCreate() {
    Vue.prototype.$bus = this; //安装全局总线
    const currentUrl = window.location.href;

    //获取客户当前网页路径下的驱动配置
    axios.get(`${currentUrl}cfg.json`).then(result => {
      var driverCfg = result.data;

      this.$bus.$emit('setDriverCfg',driverCfg);

      if(typeof driverCfg.light != "undefined" && typeof driverCfg.dark != "undefined") {
        this.$bus.$emit('themeButtonShow',true);
      }
      else {
        this.$bus.$emit('themeButtonShow',false);
      }

      //关闭所有打印
      if(driverCfg.debug.log == false) {
        console.log = () => {};
      }

      //修改驱动的名称
      document.title = driverCfg.title;

      //获取配置文件下的用到的语言
      var languages = [];
      const promises = driverCfg.language.map (language => axios.get(`${currentUrl}lang/${language}.json`).then(result => {
        //获取客户当前网页路径下的语言包
        languages.push(result.data);
      }));

      Promise.all(promises).then(responses => {
        // 所有请求都成功了，这里的代码会在所有请求完成后执行
        // 获取语言下拉框选项
        var langOptions = [];
        for(let i = 0;i < languages.length;i++)
        {
          let option = {
            value:driverCfg.language[i],
            option:languages[i].Language,
          }
          langOptions.push(option);
        }
        console.log("cfg:",currentUrl,driverCfg,langOptions,languages);
        //设置全局语言包
        this.$bus.$emit('setGlobalLanguages',langOptions,languages);
      }).catch(error => {
          // 任何一个请求失败了，这里的代码会执行
          console.error('An error occurred:', error);
      });
    }).catch(error => {
      this.$bus.$emit('noFound',true);
    });

    //获取controlhub.top路径下的sensor配置
    axios.get(window.location.origin+`/sensor.json`).then(result => {
      var sensor = result.data;
      this.$bus.$emit('setGlobalSensor',sensor);
    }).catch(error => {
      notFound = true;
      this.$bus.$emit('noFound',true);
    });
    //console.log("main",this,config,languages,langOptions,Vue.prototype);
  }
}).$mount("#app");
