<template>
  <div id='home' class="flex_column home_width" style="height: 95vh;justify-content: space-between;">
    <div class="flex_row" style="justify-content: flex-end;">
      <div class="flex_row" style="justify-content: space-evenly; width: 80%;">
        <div class="flex_row_center" v-if="driverCfg.demo">
          <span>输入密码</span>
          <el-input
          v-model="password"
          show-password
          class="password_class">
          </el-input>
          <el-button 
          @click="handlePasswordClick"
          class="ok_class"
          lang="OK">确定</el-button>
        </div>


        <div v-if="driverCfg.demo">
          <span>设备类型：</span>
          <select 
          v-model="device">
            <option 
            v-for="item in deviceOption" 
            :key="item.value" 
            :value="item.value">
          {{item.option}}</option>
          </select>
        </div>


        <div v-if="driverCfg.demo">
          <span>Sensor类型：</span>
          <select 
          v-model="selectSensor">
            <option 
            v-for="item in sensorOptions" 
            :key="item.value" 
            :value="item.value">
          {{item.option}}</option>
          </select>
        </div>
      </div>
         <!-- 语言选择下拉框 -->
      <select 
      v-model="language" 
      @change="handleLanguageChange">
        <option 
          v-for="item in languageOptions" 
          :key="item.value" 
          :value="item.value">
          {{ item.option }}
        </option>
      </select>      
    </div>

    <!-- 欢迎和介绍 -->
    <div class="flex_column" style="align-items: center;">
      <p class="title_font_size" lang="Welcome">欢迎使用Control HUB WEB</p> 
      <p class="subtitle_font_size" lang="SelectDevice">在这里您可以设置您的设备，包含按键、sensor、灯光及其它配置;</p>
      <p class="subtitle_font_size" lang="SupportSystem">适配 Mac OS/Window/Linux 等主流操作系统</p>
      <p class="subtitle_font_size" lang="SupportBrowser">仅支持Google Chrome，Microsoft Edge，Opera等浏览器访问</p>
      <el-button 
      @click="handleConnetClick" 
      :disabled="isSupportConnect == false"
      class="connect_button"
      lang="Connect">连接设备+</el-button>
      <span lang="OperationTips">操作提示：</span>
    </div>

    <!-- 操作提示和版本 -->
     <div class="flex_column">
      <div class="flex_row" style="justify-content: space-between; align-items: center;">
        <div style="display: flex; align-items: baseline;">
          <span class="pair_class" lang="PairButtonTips">新接收器对码:</span>
          <span style="margin-left: 10px;" lang="PairTips">敲击空格键进入配对，在弹窗中选择需要对码的接收器，回车确认（30秒）</span>
        </div>

        <div class="flex_row" style="align-items: center;">
          <span class="tips_font_size" lang="Version">版本：</span>
          <span class="tips_font_size" style="margin-right: 20px;">{{version}}</span>
        </div>
      </div>

      <div v-show="showICP" class="flex_row" style="justify-content: center; align-items: baseline; margin-top: 30px;">
        <span > 
        Control Hub |<a href='https://beian.miit.gov.cn/'  class="web_span"> 粤ICP备06015730号-2</a> </span>
      </div>
     </div>


    <!-- <div class="flex_column align_center" style="justify-content: center;">
      <div class="flex_column align_center" style="justify-content: center; margin: 10px;">
        <h1>关于我们</h1>
        <span style="margin: 10px 0px;">深圳市匠盟科技有限公司（Compx Technology Ltd）是一家专注于短距离无线通讯技术，致力于成为一家技术领先的创新型方案设计公司！</span>
        <span style="width: 80vw;">匠盟科技始终专注于短距离无线通讯技术的研发，聚焦于消费电子领域，为终端生产厂商提供技术领先/稳定可靠/性价比高的一站式解决方案，经过多年的发展，公司在Bluetooth/BLE/WIFI/2.4G/5.8G等无线技术领域，积累了深厚的底层技术功底和丰富的应用开发经验！在新的市场机遇和挑战面前，解决开拓新的细分市场，目前在蓝牙音频TWS/智能输入设备/智能家居/物联网应用等细分市场，有相当的美誉度和市场占有率！</span>
        </div>
      <div class="flex_row" style="justify-content: space-evenly; align-items: center;">
        <span>地址：深圳市南山区科技园高新北六道兰光科技园C408 | 电话：0755 - 8297 4111 | 传真： 0755 - 8297 4119</span>
      </div>
    </div> -->



    <tips ref="tipsDialog" :text="tipsText"></tips>
  </div>
</template>

<script>
  import HIDHandle from '@/assets/js/HIDHandle';
  import Tips from '@/components/Dialog/Tips.vue'
import axios from 'axios';

  export default {
    components:{
      Tips,
    },
    data() {
      return {
        languageOptions:[],
        languages:[],
        language:"zh-CN",
        isSupportConnect:true,
        driverCfg:{},
        version:"v1.0.0",
        lang:{},
        tipsText:'',
        device:"mouse",
        deviceOption:[
          {
            value:"mouse",
            option:"mouse"
          },
          {
            value:"keyboard",
            option:"keyboard"
          }
        ],
        normalPassword:'',
        compxPassword:"Compx123",
        password:"",
        sensor:{},
        sensorOptions:[],
        selectSensor:"3395",
        showICP:true,
      }
    },
    methods: {
      handleLanguageChange() { 
        var i = 0; 
        for(i = 0;i < this.languageOptions.length;i++) {
          if(this.language == this.languageOptions[i].value) {
            break;
          }
        }
 
        this.$bus.$emit("languageOptionsChange",this.language);
        this.$bus.$emit("languageChange",this.languages[i]);
      },
      handlePasswordClick() {
        if((this.password == this.compxPassword) || (this.password == this.normalPassword)) {
          localStorage.setItem('password',JSON.stringify(this.password));
          this.isSupportConnect = true;
        }
        else {
          this.isSupportConnect = false;
        }
      },
      //获取浏览器信息，HID服务仅支持“Chrome”，“Edge”和“Opera”，不支持“safari”和“FireFox”
      getBrowserInfo() {
        let types = ['edg','firefox','chrome','safari','opera '];
        let userAgent = navigator.userAgent.toLocaleLowerCase();
        var res = [];

        types.forEach(element => {
            if(userAgent.indexOf(element)>0){
                let rule= `${element}`+"\\/([\\d.]+)";
                res.push(element);
                res.push(userAgent.match(rule)[1]);
            }
        });

        if(res.indexOf('chrome') >-1 && res.indexOf('safari') >-1){
            if(res.length===4){
                let temp=[];
                temp.push('chrome');
                temp.push(res[res.indexOf('chrome')+1]);
                res = temp;
            }else{
            res.splice(res.indexOf('chrome'),2);
            res.splice(res.indexOf('safari'),2);
            }
        }

        if(res[0] === "chrome" || 
          res[0] === "edg" ||
          res[0] === "opera")
          {
            this.isSupportConnect = true;
          }
          else 
            this.isSupportConnect = false;
      },
      async requestDevice() {
        var connect = false;
        var driverCfg = this.driverCfg;
        var filters = [];
        var type = this.device;

        //Demo连接设备
        if(driverCfg.demo || driverCfg.visit) {
          connect = await HIDHandle.Request_Device(filters);
        }
        else {
          var pid = [];
          var devicePid = [driverCfg.pid.mouse,driverCfg.pid.keyboard];
          var mousePid = [];
          var keyboardPid = [];

          for(var j = 0;j < devicePid.length;j++) {
            var tmp = devicePid[j];
            for(var i = 0;i < tmp.wireless.length;i++) {
              pid.push(tmp.wireless[i]);

              if(j == 0) {
                mousePid.push(tmp.wireless[i]);
              }
              else if(j == 1) {
                keyboardPid.push(tmp.wireless[i]);
              }
            }
            for(var i = 0;i < tmp.wired.length;i++) {
              pid.push(tmp.wired[i]);

              if(j == 0) {
                mousePid.push(tmp.wired[i]);
              }
              else if(j == 1) {
                keyboardPid.push(tmp.wired[i]);
              }
            }  
          }

          for(let i = 0;i < pid.length;i++)
          {
            var filter = {
              vendorId: Number.parseInt(driverCfg.vid),
              productId: Number.parseInt(pid[i]),
            }
            filters.push(filter);
          }
          console.log("filters",filters);
          connect = await HIDHandle.Request_Device(filters);

          if(connect) {
            //当前设备是鼠标还是键盘
            for(var i = 0;i < mousePid.length;i++) {
              if(Number.parseInt(mousePid[i]) == HIDHandle.devicePID) {
                type = "mouse";
                break;
              }
            }

            for(var i = 0;i < keyboardPid.length;i++) {
              if(Number.parseInt(keyboardPid[i]) == HIDHandle.devicePID) {
                type = "keyboard";
                break;
              }
            } 
            HIDHandle.deviceInfo.type = type;     
          }
        }
        
        return connect;
      },
      // 选择需要操作的HID设备
      async handleConnetClick() {
        if(this.isSupportConnect) {
          var connect = await this.requestDevice();

          if(connect) {
            var info = await HIDHandle.Get_Device_Info();

            if(this.driverCfg.visit) {
              var path = "demo.png";
              var sensor = "3950";
              var deviceCfg = this.driverCfg.mouse[0].cfg[0];
              if(HIDHandle.deviceInfo.type == "mouse") {
                HIDHandle.deviceInfo.mouseCfg.sensor.type = deviceCfg.sensor;
                HIDHandle.deviceInfo.mouseCfg.sensor.cfg = this.sensor[sensor] || null;
                HIDHandle.deviceInfo.mouseCfg.keysCount = deviceCfg.keys.length;

                this.$bus.$emit("setMouseDefaultCfg",deviceCfg);
              }

              path = HIDHandle.deviceInfo.type + "/" + path;

              this.$bus.$emit("setDevicePath",path);
              this.$bus.$emit('backToHome',false);            
            }
            else {
              //cid或者mid为0说明设备不在线或者没有配对，需要提醒设备处于离线状态
              // if((info.cid != 0) && (info.mid != 0)) {
              //   var device;
              //   var found = false;
              //   var deviceCfg;
              //   var cid;

              //   if(HIDHandle.deviceInfo.type == "mouse") {
              //     device = this.driverCfg.mouse;    
              //   }
              //   else {
              //     device = this.driverCfg.keyboard;        
              //   }
              //   deviceCfg = device[0].cfg[0];
              //   if(this.driverCfg.demo) {
              //     found = true;
              //   }
              //   else {
              //     for(var i = 0;i < device.length;i++) {
              //       if(info.cid === device[i].cid) {
              //         for(var j = 0;j < device[i].cfg.length;j++) {
              //           if(info.mid === device[i].cfg[j].mid) {
              //             found = true;
              //             console.log("connect info:",info,device[i].cfg[j]);
              //             deviceCfg = device[i].cfg[j];
              //             cid = device[i].cid;
              //             break;
              //           }
              //         }              
              //       }
              //     }
              //   }
              //   //未找到符合要求的cid，mid，提醒暂不支持该设备
              //   if(found == false) {
              //     this.tipsText = this.lang.DialogDeviceUnsupport;
              //     this.$refs.tipsDialog.visible = true;
              //   }
              //   else {
              //     var path = "demo.png";
              //     var sensor = deviceCfg.sensor;
              //     if(this.driverCfg.demo) {
              //       sensor = this.selectSensor;
              //     }
              //     else {                 
              //       //设置设备的图片
              //       path = cid.toString(16).padStart(2, '0') +
              //       deviceCfg.mid.toString(16).padStart(2, '0') + '.png';
              //     }

              //     if(HIDHandle.deviceInfo.type == "mouse") {
              //       HIDHandle.deviceInfo.mouseCfg.sensor.type = sensor;
              //       HIDHandle.deviceInfo.mouseCfg.sensor.cfg = this.sensor[sensor] || null;
              //       HIDHandle.deviceInfo.mouseCfg.keysCount = deviceCfg.keys.length;

              //       HIDHandle.deviceInfo.mouseCfg.defaultLongDistance = deviceCfg.longDistance;
              //       this.$bus.$emit("setMouseDefaultCfg",deviceCfg);
              //     }

              //     path = HIDHandle.deviceInfo.type + "/" + path;
              //     console.log("path:",path);
              //     this.$bus.$emit("setDevicePath",path);
              //     this.$bus.$emit('backToHome',false);

              //     await HIDHandle.Device_Connect();
              //   }
              // }
              // else {
              //   this.tipsText = this.lang.DialogOffline;
              //   this.$refs.tipsDialog.visible = true;
              // }  

              var found = false;
              var device;
              var deviceCfg;
              var path = "demo.png";
              var sensor = "3395";

              if(HIDHandle.deviceInfo.type == "mouse") {
                device = this.driverCfg.mouse;    
              }
              else {
                device = this.driverCfg.keyboard;        
              }

              if(this.driverCfg.demo) {
                deviceCfg = device[0].cfg[0];
                sensor = this.selectSensor;
                found = true;
              }
              else {                 
                //cid或者mid为0说明设备不在线或者没有配对，需要提醒设备处于离线状态
                if((info.cid != 0) && (info.mid != 0)) {
                  var cid;

                  for(var i = 0;i < device.length;i++) {
                    if(info.cid === device[i].cid) {
                      for(var j = 0;j < device[i].cfg.length;j++) {
                        if(info.mid === device[i].cfg[j].mid) {
                          found = true;
                          console.log("connect info:",info,device[i].cfg[j]);
                          deviceCfg = device[i].cfg[j];
                          cid = device[i].cid;
                          break;
                        }
                      }              
                    }
                  }
                  
                  //未找到符合要求的cid，mid，提醒暂不支持该设备
                  if(found == false) {
                    this.tipsText = this.lang.DialogDeviceUnsupport;
                    this.$refs.tipsDialog.visible = true;
                  }
                  else {
                    //设置设备的图片
                    path = cid.toString(16).padStart(2, '0') +
                    deviceCfg.mid.toString(16).padStart(2, '0') + '.png';
                  }
                }
                else {
                  this.tipsText = this.lang.DialogOffline;
                  this.$refs.tipsDialog.visible = true;
                }                         
              }

              if(found) {
                if(HIDHandle.deviceInfo.type == "mouse") {
                  HIDHandle.deviceInfo.mouseCfg.sensor.type = sensor;
                  HIDHandle.deviceInfo.mouseCfg.sensor.cfg = this.sensor[sensor] || null;
                  HIDHandle.deviceInfo.mouseCfg.keysCount = deviceCfg.keys.length;

                  HIDHandle.deviceInfo.mouseCfg.defaultLongDistance = deviceCfg.longDistance;
                  this.$bus.$emit("setMouseDefaultCfg",deviceCfg);
                }

                path = HIDHandle.deviceInfo.type + "/" + path;

                this.$bus.$emit("setDevicePath",path);
                this.$bus.$emit('backToHome',false);

                await HIDHandle.Device_Connect();
              }
            }        
          } 
        }
      },
    },
    created() {
      this.getBrowserInfo();

      this.$bus.$on("setGlobalSensor",sensor => {
        this.sensor = sensor;
        var keys = Object.keys(sensor);
        this.sensorOptions = [];
        for(var i = 0;i < keys.length;i++) {
          if(sensor[keys[i]].hasOwnProperty('range')) {
            var tmp = {
              value:keys[i],
              option:keys[i]
            }
            this.sensorOptions.push(tmp);
          }
        }
      });     

      this.$bus.$on("setGlobalLanguages",(options,languages) => {
        this.languageOptions = options;
        this.languages = languages; 
        this.handleLanguageChange();
      });

      this.$bus.$on("setDriverCfg",driverCfg => {
        this.driverCfg = driverCfg;
        this.version = driverCfg.version; 

        if(this.driverCfg.vid == '') {
          this.isSupportConnect = false;
          this.showICP = true;
        }
        else {
          this.showICP = false;
        }

        if(driverCfg.demo) {
          const now = new Date();
          const year = now.getFullYear();
          const moth = (now.getMonth() + 1).toString().padStart(2,'0');
          const day = now.getDate().toString().padStart(2,'0');
          const time = `${year}-${moth}-${day}`;
          this.normalPassword = btoa(time);
          console.log("time:",time,this.normalPassword);
          if((this.password != this.compxPassword) && (this.password != this.normalPassword)) {
            this.isSupportConnect = false;
          }
        }
      });

      this.$bus.$on("languageOptionsChange",value => {
        this.language = value;
        localStorage.setItem('locale',JSON.stringify(value));
      });

      this.$bus.$on("languageChange",lang => {
        if(typeof lang != 'undefined'){
          this.lang = lang;
          let elements = document.querySelectorAll('[lang]');

          // 遍历每个需要翻译的元素
          elements.forEach(element => {
          let id = element.getAttribute('lang');  
          if (lang[id]) {
              element.innerText = lang[id]; // 使用翻译后的文本替换原来的文本
            }
          });
        }
      });   
    },
    mounted() {
      //查找应用（App）是否保存了上次选择的语言
      var item = localStorage.getItem('locale');
      if(item) {
        var tmp = JSON.parse(item);
        this.language = tmp;
      }
      else
      {
        //获取浏览器的语言
        if(navigator.language) {
          var lans = [];
          for(var i = 0;i < this.languageOptions.length;i++) {
            lans.push(this.languageOptions[i].value);
          }

          console.log("language:",navigator.language,this.languages,this.languageOptions,lans);

          //如果语言包里面有浏览器的语言，就调用该语言
          if(lans.includes(navigator.language.toLowerCase())) {
            this.language = navigator.language.toLowerCase();
          }
          else
            this.language = "en";
        }
        else
          this.language = "en";
      }
      this.handleLanguageChange();

      item = localStorage.getItem('password');
      if(item) {
        var tmp = JSON.parse(item);
        this.password = tmp;
      }
    },
    beforeDestroy() {
      this.$bus.$off('setGlobalSensor');
      this.$bus.$off('setGlobalLanguages');
      this.$bus.$off('setDriverCfg');
      this.$bus.$off('languageChange');
      this.$bus.$off('languageOptionsChange');
    }
  }
</script>

<style lang="less" scoped>
#home {
  color:var(--home-font-color);

  span {
    color:var(--home-font-color);
  }

  select {
    color:var(--home-font-color);
  }

  .el-button {
    color:var(--home-font-color);
  }
}


:deep(.el-input__inner) {
  color:var(--home-font-color);
  }

:deep(.el-input .el-input__clear) {
  color:var(--home-font-color);
  }


/* 主页connect按钮样式*/
.connect_button {
  margin-top: calc(1.5 * var(--title-font-size));
  margin-bottom: calc(0.5 * var(--title-font-size));
  font-size: var(--subtitle-font-size);
  border-radius: calc(var(--subtitle-font-size) / 3);
  height: calc(3 * var(--subtitle-font-size));
  width: calc(10 * var(--subtitle-font-size));

  color:var(--font-color);
  background-color: var(--home-theme-color);
  border-color: var(--home-theme-color);
  &:hover {
    color:var(--home-theme-color);
    background-color: transparent;
    border-color: var(--border-color);
  }  

  &:disabled {
    margin-top: calc(1.5 * var(--title-font-size));
    margin-bottom: calc(0.5 * var(--title-font-size));
    font-size: var(--subtitle-font-size);
    border-radius: calc(var(--subtitle-font-size) / 3);
    height: calc(3 * var(--subtitle-font-size));
    width: calc(10 * var(--subtitle-font-size));
    
    color:var(--font-color);
    background-color: var(--home-theme-color);
    border-color: var(--home-theme-color);
  }
}

.web_span {
  cursor: pointer;
  text-decoration: none
}
.pair_class {
  border: 2px solid var(--home-theme-color);
  border-radius: calc(var(--font-size) * 0.5); 
  padding: calc(var(--font-size) * 0.5);
  background-color: transparent;
}

.password_class {
  width: calc(var(--font-size) * 15);
  height: calc(var(--font-size) * 2);

}

.ok_class {
  width: calc(var(--font-size) * 3);
  height: calc(var(--font-size) * 2);
  margin-left: calc(var(--font-size) * 0.5);
}


</style>